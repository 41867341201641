import React, { useState } from 'react'
import PageLayout from '../components/PageLayout';
import ArtistsList from '../components/ArtistsList';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Colors } from '../utils/constants';
import { LeftArrowWithText, RightArrowWithText } from '../components/ArrowWithText';
import { Input } from '../components/InputComponents';
import debounce from 'lodash.debounce';
import { IoMdSearch } from 'react-icons/io';

const StyledSearchIcon = styled(IoMdSearch)`
  :hover {
    color: ${Colors.EYA_RED};
  }
  @media screen and (max-width: 414px) {
    width: 1.5em;
  }
`;

const StyledInput = styled(Input)`
  @media screen and (max-width: 414px) {
    font-size: 12px;
    padding: 4px 7px;
    width: 60%;
  }
`;

const InputContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  transition: width 2s;
`;

function ArtistsPageTemplate({
  data,
  pageContext,
  className
}) {
  const [searchText, setSearchText] = useState('');
  const [showSearchInput, setShowSearchInput] = useState(false);
  const {currentPage, numPages} = pageContext;
  const hasNext = currentPage !== numPages;
  const hasPrevious = currentPage !== 1;
  const currPageArtistsIds = data.currentPageArtists.edges.map(edge => {
    return edge.node.frontmatter.id
  });
  const allArtists = data.allArtists.edges.map(edge => {
    return {
      id: edge.node.frontmatter.id,
      name: edge.node.frontmatter.name
    };
  });
  const updateValue = debounce(val => setSearchText(val), 200);

  const handleSearch = (e) => {
    updateValue(e.target.value);
  }

  const artistsIds = () => {
    if(!searchText) { return currPageArtistsIds; }
    const matchedIds = allArtists
      .filter((artist) => {
        const artistName = artist.name.toLowerCase();
        return artistName.indexOf(searchText.toLowerCase()) !== -1
      })
      .map(artist => artist.id);
    return matchedIds;
  };

  const renderTitleRight = () => (
    <InputContainer>
      {
        !showSearchInput
          ? <StyledSearchIcon size="2em" onClick={() => setShowSearchInput(true)}/>
          : <StyledInput
              aria-label="search"
              placeholder="Search"
              onChange={handleSearch}
            />
      }

    </InputContainer>
  );
  const renderControlBar = () => (
    <div className="control-bar">
      <div className="left">{hasPrevious && 
        <LeftArrowWithText 
          title="Previous"
          to={`/artists/${currentPage - 1}`}
        />}
      </div>
      <div className="right">{hasNext &&
        <RightArrowWithText
          title="Next"
          to={`/artists/${currentPage + 1}`}
        />}
      </div>
    </div>
  );
  return (
    <div className={className}>
      <PageLayout 
        title="Artists"
        renderTitleRight={renderTitleRight}
        uppercaseTitle
        titleAnim
      >
        <ArtistsList artistsList={artistsIds()}/>
        {renderControlBar()}
      </PageLayout>
    </div>
  )
}

export default styled(ArtistsPageTemplate)`
width: 100%;
.control-bar {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  .left {
    justify-self: start;
  }
  .right {
    justify-self: end;
  }
}
`;

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    currentPageArtists: allMdx(
      sort: { order: ASC, fields: [frontmatter___id] }
      filter: {fileAbsolutePath: {glob: "**/src/data/artists/*.md"}}
      limit: $limit
      skip: $skip
    ) {
      edges {
        node{
          frontmatter {
            id
          }
        }
      }
    },
    allArtists: allMdx(
      sort: { order: ASC, fields: [frontmatter___id] }
      filter: {fileAbsolutePath: {glob: "**/src/data/artists/*.md"}}
    ) {
      edges {
        node{
          frontmatter {
            id
            name
          }
        }
      }
    }
  }
`;